import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import Table from '../../components/Table/simpletable.component';
import 'react-datepicker/dist/react-datepicker.css';

const validator = new SimpleReactValidator();

const statusData = [
  {
    id: 1,
    label: 'Pending',
    classes: 'text-warning',
    color: '',
  },
  {
    id: 2,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 3,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 4,
    label: 'Complete',
    classes: 'text-success',
    color: '',
  },
];

function StudentHomework(props) {
  const [rows, setRows] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [classId, setClassId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const {yearId} = props;

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [2, 4],
      isDisplay: false,
    },
    {
      id: 'homeworkTitle',
      label: 'Homework Title',
      roleAccess: [2, 4],
      render: ({ id, homeworkTitle }) => {
        return (
          <>
            <a
              href="javascript:void(0)"
              className="p-r-2 font-weight-semibold"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.history.push(`/studenthomeworkdetails/${id}`);
              }}
            >
              {homeworkTitle}
            </a>
          </>
        );
      },
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [2, 4],
      render: ({ subject }) => {
        return (
          <>
            {subject}
          </>
        );
      },
    },
    {
      id: 'date',
      label: 'Date',
      roleAccess: [2, 4],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [2, 4],
      render: ({ status, id }) => {
        return (
          <>
            <span
              className={getStatusColor(status, 'classes')}
            >
              {getStatusColor(status, 'label', id)}
            </span>
          </>
        );
      },
    },
  ];

  const getStatusColor = (status, key) => {
    const obj = statusData.filter((st) => {
      return Number(st.id) === Number(status);
    });
    if (!CustomFunctions.checkIfEmpty(obj, 'A')) {
      return obj[0][key];
    }
    return '';
  };

  useEffect(() => {
    const id = props.match.params.studentId;
    setClassId(yearId);
    if (id) {
      setStudentId(id);
      const uRole = CustomFunctions.getLocalStorage('role_id');
      setUserRole(Number(uRole));
      getTableData(id,yearId);
    }
  }, [yearId]);

   const getTableData = async (id, classid, pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;
    const endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}`;
    const params = {
      student_id: id,
      class_id : classid,
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction:'descending',
      sort_by:'homework__start_date',
    };

    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((student) => {
        const frmDate = new Date(student.start_date.toLocaleString());
        const endDate = new Date(student.end_date.toLocaleString());
        const setRowData = {
          id: student.id,
          homeworkTitle: student.title ? student.title : '-',
          subject : student.subject_name,
          date:
            student.start_date && student.end_date
              ? `${CustomFunctions.getDMYDate(
                  frmDate
                )} - ${CustomFunctions.getDMYDate(endDate)}`
              : '-',
          status: student.status,
          rowClass: Number(student.status) === 3 ? 'table-red-bg' : '',
        };
        row.push(setRowData);
        return student;
      });
      setRows(row);
    }
  };

  validator.purgeFields();
  return (
    <>
      <section className="content-area">
        <div className="mt-5">
          <Row>
            <Col className="mt-3">
              <Table
                role={Number(userRole)}
                columns={columns}
                rows={rows}
                showPagination
                paginationProps={{
                  ...pagination,
                  activePage: pagination.current_page,
                  onPageChange: (pageNo) => {
                    getTableData(studentId, classId, pageNo);
                  },
                }}
                tableClass="table-striped"
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentHomework);
