import { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Tabs, Tab, Card } from 'react-bootstrap';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import StudentHomework from './studentAcademicHomework.component';
import StudentVirtualClass from './studentAcademicVirtualClass.component';
import StudentStudyMaterial from './studentAcademicStudyMaterial.component';
import StudentTutorSubject from './studentAcademicTutorSubject.component';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import { Helmet, DropdownInput } from '../../components/FormInputs';

const items = [
  { to: '/', label: 'Home' },
  { to: '', label: 'Students' },
];

const StudentAcademicDetails = (props) => {
  const history = useHistory();
  const [studentName, setStudentName] = useState('');
  const [title, setTitle] = useState('');
  const [yearList, setYearList] = useState([]);
  const [selOptionYear, setSelOptionYear] = useState(null);
  const [classId, setClassId] = useState(null);

  useEffect(async () => {
    const StudentId = props.match.params.studentId;
    if (!Number(StudentId)) {
      history.push('/');
      return;
    }
    setTitle('Student Profile');
    getStudentClasses(StudentId);
    getStudentDetails(StudentId);
  }, []);

  const getStudentDetails = async (id) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.studentUrl}${id}/`);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setStudentName(data.data.full_name);
    }
  };

  const getStudentClasses = async (id) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.studentClassUrl}?student_id=${id}`);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setYearList(data.data);
      if(data && data.data && data.data.length > 0 ){
        setSelOptionYear({label:data.data[0].name,
          id: data.data[0].id,
          value: data.data[0].id});
        };
        setClassId(data.data[0].id);
      }
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((mat) => {
      return yearOptions.push({
        label: mat.name,
        id: mat.id,
        value: mat.id,
      });
    });
    return yearOptions;
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Student Profile</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <Card>
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="text-primary mt-2 mb-2">Student Profile</h4>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href=""
                  onClick={() => history.push('/student')}
                  className="btn-link"
                >
                  Back
                </a>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              <div className="student-profile-row align-items-center">
                  <div className="student-profile-title-name">
                    <h3 className="card-title mb-0">{studentName || ''}</h3>
                  </div>
                  <div className="ml-10 school-year">
                  <div className="font-weight-bold mt-2">
                      <span>School Year</span>
                  </div>
                </div>
                  <div className="schoolyear-dropdown">
                  <div className="font-weight-bold mt-4">
                      <DropdownInput
                          placeholder="Select School Year"
                          options={getYearData()}
                          defaultValue={selOptionYear}
                          onSelectHandler={(select) => {
                            setSelOptionYear(select);
                            setClassId(select.id);
                       }}
                      />
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="StudentHomework"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="StudentHomework" title="Homework Submission">
                  <StudentHomework yearId={classId} />
                </Tab>

                <Tab eventKey="StudentVirtualClass" title="Virtual Class">
                  <StudentVirtualClass yearId={classId} />
                </Tab>

                <Tab eventKey="StudentStudyMaterial" title="Study Material">
                  <StudentStudyMaterial yearId={classId} />
                </Tab>

                <Tab eventKey="StudentTutorSubject" title="Tutors & Subjects">
                  <StudentTutorSubject yearId={classId} />
                </Tab>
              </Tabs>
            </div>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default withRouter(StudentAcademicDetails);
